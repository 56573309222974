import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';
import { FancyDropdownMenuItem } from 'dpl/components/FancyDropdown';
import { ROOT_HOST } from 'dpl/shared/constants/urls';

import UserHeaderLoggedInBreederMenu from './UserHeaderLoggedInBreederMenu';
import UserHeaderLoggedInBuyerMenu from './UserHeaderLoggedInBuyerMenu';

export default function UserHeaderLoggedInDropdownContent({
  user,
  profilePath,
  totalNotificationsCount,
  isPublished,
  handleLogoutClick
}) {
  const isBreeder = user?.breeder;
  const isBuyer = user?.buyer;

  return (
    <>
      {isBreeder && (
        <UserHeaderLoggedInBreederMenu
          user={user}
          totalNotificationsCount={totalNotificationsCount}
          profilePath={profilePath}
          isPublished={isPublished}
        />
      )}
      {isBuyer && (
        <UserHeaderLoggedInBuyerMenu
          user={user}
          totalNotificationsCount={totalNotificationsCount}
        />
      )}
      <div className="bt b--near-white mv2" />
      <FancyDropdownMenuItem itemKey="logout">
        {/* New menu style should only be applied to the breeder app for now */}
        {isBreeder ? (
          <a
            href={ROOT_HOST}
            onClick={handleLogoutClick}
            className="flex items-center w-100 db pv2 ph4 font-16 tertiary hover-midnight"
          >
            <Icon
              className="mr2"
              name="fetch-logout-web"
              height="24px"
              width="24px"
            />
            <span>Log out</span>
          </a>
        ) : (
          <a
            href={ROOT_HOST}
            onClick={handleLogoutClick}
            className="w-100 db pv2 ph4"
          >
            Log out
          </a>
        )}
      </FancyDropdownMenuItem>
    </>
  );
}

UserHeaderLoggedInDropdownContent.propTypes = {
  user: PropTypes.shape({
    breeder: PropTypes.bool,
    buyer: PropTypes.bool
  }).isRequired,
  profilePath: PropTypes.string,
  totalNotificationsCount: PropTypes.number.isRequired,
  isPublished: PropTypes.bool,
  handleLogoutClick: PropTypes.func.isRequired
};

UserHeaderLoggedInDropdownContent.defaultProps = {
  isPublished: false,
  profilePath: null
};
