import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { toggleOverlayAppContent } from 'dpl/shared/utils';
import withToggle from 'dpl/decorators/withToggle';
import FancyDropdown, {
  FancyDropdownToggle,
  FancyDropdownMenu
} from 'dpl/components/FancyDropdown';

import UserHeaderLoggedInDropdownHeading from './UserHeaderLoggedInDropdownHeading';
import UserHeaderLoggedInDropdownContent from './UserHeaderLoggedInDropdownContent';

class UserHeaderLoggedInDropdown extends Component {
  static propTypes = {
    currentUser: PropTypes.shape({
      logout: PropTypes.func.isRequired,
      value: PropTypes.shape({
        data: PropTypes.shape({
          name: PropTypes.string,
          profile_url: PropTypes.string,
          profile_photo_url: PropTypes.string,
          breeder: PropTypes.bool,
          buyer: PropTypes.bool,
          published: PropTypes.bool,
          entitlements: PropTypes.arrayOf(PropTypes.string)
        }).isRequired
      }).isRequired
    }).isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    slim: PropTypes.bool,
    totalNotificationsCount: PropTypes.number.isRequired,
    handleLogout: PropTypes.func
  };

  static defaultProps = {
    slim: false,
    handleLogout: null
  };

  handleDropdownChange = isOpen => {
    this.props.toggle(isOpen);
    toggleOverlayAppContent(isOpen);
  };

  componentWillUnmount() {
    toggleOverlayAppContent(false);
  }

  handleLogoutClick = event => {
    event.preventDefault();
    const { href } = event.currentTarget;
    this.props.handleLogout?.() ??
      this.props.currentUser.logout().then(() => {
        window.location = href;
      });
  };

  render() {
    const { currentUser, isOpen, slim, totalNotificationsCount } = this.props;

    const {
      data: { name, profile_url: profilePath, published: isPublished },
      data: user
    } = currentUser.value;

    return (
      <div className="UserHeaderLoggedInDropdown">
        <FancyDropdown
          allowPropagationOnClose
          isOpen={isOpen}
          onChange={this.handleDropdownChange}
          menuPosition="right"
        >
          <FancyDropdownToggle>
            <UserHeaderLoggedInDropdownHeading
              slim={slim}
              name={name}
              hasNotifications={Boolean(totalNotificationsCount)}
              isOpen={isOpen}
            />
          </FancyDropdownToggle>
          <FancyDropdownMenu className="UserHeaderLoggedInDropdown__menu br12 box-shadow-2 bg-white mt2 ph1 pv2 ba b--light-gray w-auto">
            <UserHeaderLoggedInDropdownContent
              user={user}
              profilePath={profilePath}
              totalNotificationsCount={totalNotificationsCount}
              isPublished={isPublished}
              handleLogoutClick={this.handleLogoutClick}
            />
          </FancyDropdownMenu>
        </FancyDropdown>
      </div>
    );
  }
}

export default withToggle({ propName: 'isOpen' })(UserHeaderLoggedInDropdown);
