import useResourceQuery from 'dpl/shared/fetching/hooks/useResourceQuery';
import { get } from 'dpl/shared/utils/object';

import { notificationCountsQueryDefinition } from './queryDefinitions';

export const NOTIFICATION_COUNT_KEYS = {
  unreadMessagesCount: 'unread_messages_count',
  unseenApplicationsCount:
    'breeder_profile_notifications.unseen_applications_count',
  outstandingBipsCount: 'breeder_profile_notifications.outstanding_bips_count'
};

export default function useNotificationCounts() {
  const query = useResourceQuery(notificationCountsQueryDefinition);
  const fetchNotifications = query.refetch;

  if (query.isFetching) {
    return {
      fetchNotifications,
      hasNotifications: false,
      isFetching: true,
      isSuccess: false,
      notifications: { unreadMessagesCount: 0 },
      totalNotificationsCount: 0
    };
  }

  let totalNotificationsCount = 0;
  const { data } = query.value;

  // return an object of { unreadMessagesCount: <int>, ... } with summation of total count
  const notifications = Object.entries(NOTIFICATION_COUNT_KEYS).reduce(
    (acc, [name, key]) => {
      const value = get(data, key) || 0;
      totalNotificationsCount += value;
      return { ...acc, [name]: value };
    },
    {}
  );

  return {
    fetchNotifications,
    hasNotifications: Boolean(totalNotificationsCount),
    isFetching: false,
    isSuccess: true,
    notifications: {
      ...notifications,
      hasUnseenNewsItems:
        get(data, 'breeder_profile_notifications.has_unseen_news_items') ||
        false
    },
    totalNotificationsCount
  };
}
