import React from 'react';
import PropTypes from 'prop-types';

import {
  BUYER_MESSAGES_PATH,
  BUYER_APPLICATIONS_PATH,
  BUYER_FAVORITES_PATH,
  BUYER_PAYMENTS_PATH,
  BUYER_SETTINGS_PATH,
  HELP_PATH,
  PUPPY_TRAINING_PROGRAM,
  PUPPY_TRAINING_PROGRAM_LANDING,
  ROOT_HOST
} from 'dpl/shared/constants/urls';
import { FancyDropdownMenuItem } from 'dpl/components/FancyDropdown';
import { hasPuppyCollegeAccess } from 'dpl/shared/buyer/utils';
import NotificationCount from 'dpl/common/components/NotificationCount';
import SmartLink from 'dpl/components/SmartLink';

export default function UserHeaderLoggedInBuyerMenu({
  user,
  totalNotificationsCount
}) {
  const puppyTrainingLink = hasPuppyCollegeAccess(user?.entitlements)
    ? PUPPY_TRAINING_PROGRAM
    : PUPPY_TRAINING_PROGRAM_LANDING;

  return (
    <>
      <FancyDropdownMenuItem itemKey="messages">
        <SmartLink
          to={`${ROOT_HOST}${BUYER_MESSAGES_PATH}`}
          className="flex w-100 align-items-center pv2 ph4"
        >
          <span className="flex-auto">Messages</span>
          {Boolean(totalNotificationsCount) && (
            <NotificationCount count={totalNotificationsCount} />
          )}
        </SmartLink>
      </FancyDropdownMenuItem>
      <FancyDropdownMenuItem itemKey="applications">
        <SmartLink
          to={`${ROOT_HOST}${BUYER_APPLICATIONS_PATH}`}
          className="w-100 db pv2 ph4"
        >
          Applications
        </SmartLink>
      </FancyDropdownMenuItem>
      <FancyDropdownMenuItem itemKey="favorites">
        <SmartLink
          to={`${ROOT_HOST}${BUYER_FAVORITES_PATH}`}
          className="w-100 db pv2 ph4"
        >
          Favorites
        </SmartLink>
      </FancyDropdownMenuItem>
      <FancyDropdownMenuItem itemKey="payments">
        <SmartLink
          to={`${ROOT_HOST}${BUYER_PAYMENTS_PATH}`}
          className="w-100 db pv2 ph4"
        >
          Payments
        </SmartLink>
      </FancyDropdownMenuItem>
      <FancyDropdownMenuItem itemKey="settings">
        <SmartLink
          to={`${ROOT_HOST}${BUYER_SETTINGS_PATH}`}
          className="w-100 db pv2 ph4"
        >
          Account Settings
        </SmartLink>
      </FancyDropdownMenuItem>
      <FancyDropdownMenuItem itemKey="help">
        <a href={`${ROOT_HOST}${HELP_PATH}`} className="w-100 db pv2 ph4">
          Help
        </a>
      </FancyDropdownMenuItem>
      <FancyDropdownMenuItem itemKey="puppy-training">
        <a
          href={`${ROOT_HOST}${puppyTrainingLink}`}
          className="w-100 db pv2 ph4"
        >
          Puppy Training
        </a>
      </FancyDropdownMenuItem>
    </>
  );
}

UserHeaderLoggedInBuyerMenu.propTypes = {
  user: PropTypes.shape({
    entitlements: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  totalNotificationsCount: PropTypes.number.isRequired
};
