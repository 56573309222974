import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function NotificationCount({
  className,
  count,
  round,
  read,
  truncateAfter,
  title,
  unreadColor
}) {
  let text = count;
  if (typeof count === 'number' && count > truncateAfter) {
    text = `${truncateAfter}+`;
  } else if (title) {
    text = title;
  }

  return (
    <span
      className={classnames(
        'NotificationCount white f1 fw-medium flex items-center justify-center',
        className,
        {
          [`bg-${unreadColor}`]: !read,
          'bg-mid-gray': read,
          'NotificationCount--oval ph2 pv1 lh-1': !round,
          'NotificationCount--round br-100': round
        }
      )}
    >
      {text}
    </span>
  );
}

NotificationCount.propTypes = {
  className: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  round: PropTypes.bool,
  read: PropTypes.bool,
  truncateAfter: PropTypes.number,
  title: PropTypes.string,
  unreadColor: PropTypes.string
};

NotificationCount.defaultProps = {
  className: null,
  count: null,
  round: false,
  read: false,
  truncateAfter: 99,
  title: null,
  unreadColor: 'red'
};
