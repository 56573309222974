import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';
import { STATUS } from 'dpl/breeder_preferred_qualifications/utils/constants';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { get } from 'dpl/shared/utils/object';
import PreferredBreederBadge from 'dpl/breeder_preferred_qualifications/components/PreferredBreederBadge';
import UserAvatar from 'dpl/path_to_payment/components/UserAvatar';

export default function UserHeaderLoggedInDropdownHeading({
  slim,
  name,
  hasNotifications,
  isOpen
}) {
  const currentUser = useCurrentUser();
  const user = get(currentUser, 'value.data');

  const isPreferredBreeder = user.breeder_preferred_status === STATUS.PREFERRED;

  return (
    <div className="UserHeaderLoggedInDropdownHeading flex items-center justify-end">
      <span>
        <div className="relative">
          <div className="relative br-100 overflow-hidden">
            <UserAvatar user={{ ...user, id: user.user_id }} size="sm" />
            <div className="hover-bg-black transition absolute absolute--fill o-20" />
          </div>
          {hasNotifications && (
            <span className="pv1 ph1 bg-red db absolute ba bw1 b--white br-100 right-0 top-0" />
          )}
          {isPreferredBreeder && (
            <PreferredBreederBadge
              className="absolute right-0 bottom-0 nr1"
              width="16px"
              height="18px"
              hideTooltip
            />
          )}
        </div>
      </span>
      <span
        className={classnames(
          'UserHeaderLoggedInDropdown__name flex-auto tr truncate fw-medium',
          {
            'ml2 font-16 mid-gray d-none d-xl-block': !slim,
            'ml3 white f2': slim
          }
        )}
      >
        {name}
      </span>
      {!slim && (
        <div style={{ transform: isOpen ? 'rotateX(180deg)' : null }}>
          <Icon
            className="mid-gray"
            name="fetch-chevron-down"
            height="28px"
            width="28px"
          />
        </div>
      )}
    </div>
  );
}

UserHeaderLoggedInDropdownHeading.propTypes = {
  slim: PropTypes.bool,
  name: PropTypes.string.isRequired,
  hasNotifications: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired
};

UserHeaderLoggedInDropdownHeading.defaultProps = {
  slim: false
};
